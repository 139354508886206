import React, { useState, useEffect } from 'react';
import { client as apollo } from 'cccisd-apollo';
import { SessionDataEntry } from 'cccisd-laravel-assignment';
import _flatten from 'lodash/flatten';

import groupListQuery from './groupList.graphql';
import questProjectQuery from './questprojectIds.graphql';

const Fortress = window.cccisd.fortress;
const userRole = Fortress.user.acting.role.handle;

function SessionData() {
    const [groupList, setGroupList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [questId, setQuestId] = useState(null);

    useEffect(() => {
        getQuestProject();
        initializeData();
    }, []);

    async function getQuestProject() {
        const res = await apollo.query({
            query: questProjectQuery,
            fetchPolicy: 'network-only',
            variables: {},
        });

        setQuestId(res.data.flows.deployment.assignment.groupId);
    }

    async function initializeData() {
        const data = await getGroupList();

        const formattedGroupList = formatGroupList(data.groups.sfp_groupList);
        await setGroupList(formattedGroupList);
        await setIsLoading(false);
    }

    async function getGroupList() {
        let groupId = Fortress.user.acting.group_id;
        let pawnId = Fortress.user.acting.id;
        const res = await apollo.query({
            query: groupListQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId,
                groupId,
            },
        });

        return res.data;
    }

    const formatGroupList = list => {
        return list.map(
            ({
                group: { groupId },
                label,
                siteAdmin,
                facilitators,
                ancestorGroups: {
                    sfp_groupingUnit: {
                        // eslint-disable-next-line camelcase
                        descendantRoles: { sfp_groupingUnitAdminList },
                    },
                },
                childRoles: {
                    metricspawn: {
                        pawn: { pawnId, pawnHash },
                    },
                },
                // eslint-disable-next-line camelcase
                childGroups: { sfp_familyList },
            }) => {
                return {
                    groupId,
                    label,
                    pawnId,
                    pawnHash,
                    flowProps: getFlowProps(
                        sfp_familyList,
                        label,
                        siteAdmin,
                        facilitators,
                        sfp_groupingUnitAdminList
                    ),
                };
            }
        );
    };

    const formatFamilyList = familyList => {
        return familyList.map(item => {
            return {
                pawnId: item.descendantRoles.metricspawn.pawn.pawnId,
                pawnHash: item.descendantRoles.metricspawn.pawn.pawnHash,
                name: item.group.label,
            };
        });
    };

    const justFamilyLabels = familyList => {
        if (familyList.length < 1) {
            return;
        }
        if (familyList.length === 1) {
            return familyList[0].group.label;
        }

        if (familyList.length > 1) {
            return familyList.map(fam => {
                return ` ${fam.group.label}`;
            });
        }
    };

    const formatClientList = familyList => {
        const clients = familyList.map(fam =>
            fam.childRoles.sfp_clientList.map(client => {
                return {
                    name: client.user.fullName,
                    pawnId: client.pawn.pawnId,
                    pawnHash: client.pawn.pawnHash,
                };
            })
        );
        return _flatten(clients);
    };

    const formatParticipantList = familyList => {
        const participants = familyList.map(fam =>
            fam.childRoles.sfp_participantList.map(youth => {
                return {
                    name: youth.fullName,
                    pawnId: youth.pawn.pawnId,
                    pawnHash: youth.pawn.pawnHash,
                };
            })
        );
        return _flatten(participants);
    };

    const formatGroupLeaders = leaders => {
        return leaders.map(leader => {
            return {
                name: leader.pawn.user.fullName,
                pawnId: leader.pawn.pawn.pawnId,
                pawnHash: leader.pawn.pawn.pawnHash,
            };
        });
    };

    const formatCoachList = coaches => {
        return coaches.map(coach => {
            return {
                name: coach.user.fullName,
                pawnId: coach.pawn.pawnId,
                pawnHash: coach.pawn.pawnHash,
            };
        });
    };

    const getFlowProps = (
        familyList,
        group,
        siteAdmin,
        facilitators,
        // eslint-disable-next-line camelcase
        sfp_groupingUnitAdminList
    ) => {
        // eslint-disable-next-line camelcase
        const coaches = sfp_groupingUnitAdminList;
        return {
            variables: {
                sfpSite:
                    familyList.length > 0
                        ? familyList[0].ancestorGroups.sfp_site.group.label
                        : null,
                sfpGroup: group,
                sfpFamily: justFamilyLabels(familyList),
                username: Fortress.user.acting.user.username,
            },
            userLists: {
                sfp_family: formatFamilyList(familyList),
                sfp_clientList: formatClientList(familyList),
                sfp_participantList: formatParticipantList(familyList),
                sfp_siteAdmin: formatGroupLeaders(siteAdmin),
                sfp_providerList: formatGroupLeaders(facilitators),
                sfp_coach: formatCoachList(coaches),
            },
        };
    };

    const isOrgAdmin = userRole === 'sfp_orgAdmin';

    return (
        !isLoading && (
            <SessionDataEntry
                questprojectIds={[questId]}
                groups={groupList}
                title={isOrgAdmin ? 'Session Data: Site Coordinators' : 'Session Data'}
                description="Session Data supports data entry and collection at the session level."
            />
        )
    );
}

export default SessionData;
