import React from 'react';

import CommunitySurveyPlayer from './CommunitySurveyPlayer';

function CommunitySurveyEvaluator({
    match: {
        params: { respondentHash },
    },
}) {
    return (
        <CommunitySurveyPlayer
            flowProps={{
                variables: {
                    coalition_pac_county_hash: respondentHash,
                },
            }}
        />
    );
}

export default CommunitySurveyEvaluator;
